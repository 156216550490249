import { InjectableOptions, InjectableType } from './injectable';
import { Type } from './types';

type InjectableMetadata = { type: Type<any>, options: InjectableOptions, paramTypes: any[] };

export class Injector {
  
  private static _types: Map<Type<any>, any> = new Map<Type<any>, any>();
  private static _tokenTypes: Map<string, any> = new Map<string, any>();
  private static _metadata: Map<Type<any>, InjectableMetadata> = new Map<Type<any>, InjectableMetadata>();
  private static _tokensMetadata: Map<string, InjectableMetadata> = new Map<string, InjectableMetadata>();

  public static get<TType>(target: Type<TType> | string, ...args: any[]): TType {    
    if (typeof target === 'string' && Injector._tokenTypes.has(target)) {
      return Injector._tokenTypes.get(target);
    }
    else if (typeof target !== 'string' && Injector._types.has(target)) {
      return Injector._types.get(target);
    } 
    else {
      let data: InjectableMetadata = null;
      if (typeof target === 'string') {
        data = this._tokensMetadata.get(target);
      }
      else {
        data = Injector._metadata.get(target);
      }
      if (data) {        
        const injections: any[] = data.paramTypes.map(t => {
          const index: number = data.paramTypes.indexOf(t);
          if (args && args.length > index) {            
            return args[index];
          }
          else {
            return Injector.get<any>(t);
          }
        });
        const instance: TType = new data.type(...injections);
        if (data.options.type == 'singleton') {
          if (typeof target === 'string') {
            Injector._tokenTypes.set(target, instance);
          }
          else {
            Injector._types.set(target, instance);
          }
        }
        return instance;        
      }
      else {
        return null;
      }
    }
  } 
  
  public static register(target: Type<any>, options: InjectableOptions): void {
    const paramTypes: any[] = (Reflect as any).getMetadata('design:paramtypes', target) || [];
    if (options.token) {
      Injector._tokensMetadata.set(options.token, { type: target, options, paramTypes: paramTypes });
    }
    Injector._metadata.set(target, { type: target, options, paramTypes: paramTypes });
  }

  public static load<TType>(target: Type<TType>, instance: TType): void {
    Injector._types.set(target, instance);
  }
}
