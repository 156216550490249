import { DictionaryObject } from "../core/types";
import { Injectable } from "../reflection/injectable";

@Injectable({ type: 'singleton' })
export class ScrollService {   

    private _isLoaded: boolean = false;

    public init(): void {
        this._isLoaded = false;
    }

    public onLoaded(): void {
        if (!location.hash) {
            setTimeout(() => {
                this.scrollToTop('auto');
                setTimeout(() => {
                    this.scrollToTop('auto');
                    this._isLoaded = true;
                }, 200);
            });
        }
        else {
            const hashValue: string = location.hash.includes('?') ? location.hash.substring(0, location.hash.indexOf('?')): location.hash;
            if (hashValue) {
                this.scrollTo(hashValue);
            }
        }
    }   

    public register(element: Window | HTMLElement): void {
        this.setScrollValue(element);
        element.addEventListener('scroll', () => {            
            this.setScrollValue(element);
        });
    }

    private setScrollValue(element: Window | HTMLElement): void {
        if (element === window) {
            (element as Window).document.body.dataset.scroll = ((element as Window).scrollY || 0).toString();
        } 
        else {
            (element as HTMLElement).dataset.scroll = ((element as HTMLElement).scrollTop || 0).toString();
        }
    }

    public onScrolledTo(element: Window | HTMLElement, percentage: number, callback: () => void): void {
        element.addEventListener('scroll', () => { 
            if (this._isLoaded) {
                let value: number = 0;
                if (element === window) {
                    value = ((element as Window).scrollY + document.documentElement.clientHeight) / document.documentElement.scrollHeight;
                } 
                else {
                    value = ((element as HTMLElement).scrollTop + (element as HTMLElement).clientHeight) / (element as HTMLElement).scrollHeight;
                } 
                if ((value * 100) >= percentage) {
                    callback();
                }            
            }
        });
    }

    public scrollToTop(behavior: 'smooth' | 'auto' = 'smooth'): void {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior,
        });
    }

    public scrollTo(selector: string): void {
        const node = document.querySelector(selector);
        if (node) {
            node.scrollIntoView({ behavior: 'smooth' });
        } 
    }
}