declare global {
    interface Number {
        formatCurrency(currencySymbol: string, culture?: string): string;
        format(culture?: string): string;
    }
}

export class Num {

    public static addExtensionMethods(): void {
        this.addFormatCurrencyMethod();
        this.addFormatMethod();
    }

    private static addFormatCurrencyMethod(): void {
        if (!Number.prototype.formatCurrency){
            Number.prototype.formatCurrency = function (currencySymbol, culture) {
                return Num.formatCurrency(this as number, currencySymbol, culture);
            };
        };
    }

    private static addFormatMethod(): void {
        if (!Number.prototype.format){
            Number.prototype.format= function (culture) {
                return Num.format(this as number, culture);
            };
        };
    }

    public static formatCurrency(value: number, currencySymbol: string, culture?: string): string {
        let formattedValue: string = this.format(value ? value: 0, culture, 2, 2);
        if (currencySymbol == '$' || currencySymbol == '£') {
            formattedValue = `${currencySymbol}${formattedValue}`;            
        }
        else {
            formattedValue = `${formattedValue}${currencySymbol}`;
        }
        return formattedValue;
    }

    public static format(value: number, culture?: string, minimumFractionDigits: number = 0, maximumFractionDigits: number = 0): string {
        if (value != null) {
            return value.toLocaleString(culture ? culture: navigator.language, {
                minimumFractionDigits: minimumFractionDigits,
                maximumFractionDigits: maximumFractionDigits            
            });
        }
        else {
            return null;
        }
    }

    public static decimalSeparator(culture?: string): string {
        return 1.1.toLocaleString(culture).substring(1, 2);
    }

    public static thousandSeparator(culture?: string): string {
        return 1000.1.toLocaleString(culture).substring(1, 2);
    }

    public static toInteger(value: any): number {
        if (value && !isNaN(value)) {
            return parseInt(`${value}`, 10);
        }
        else {
            return null;
        }
    }

    public static toFloat(value: any): number {
        if (value && !isNaN(value)) {
            return parseFloat(`${value}`);
        }
        else {
            return null;
        }
    }

    public static isInteger(value: any): value is number {
        return !isNaN(this.toInteger(value)) && !isNaN(value);
    }
}