declare global {
    interface Date {
        addYears(years: number): Date;
        addHours(hours: number): Date;
        getAge(): number;
    }
}

export type DateTimeFormat = 'date' | 'datetime' | 'short-time';

export namespace DateTime {

    export function addExtensionMethods(): void {
        addAddYearsMethod();
        addAddHoursMethod();
        addGetAgeMethod();
    }

    function addAddYearsMethod(): void {
        if (!Date.prototype.addYears){
            Date.prototype.addYears = function (years) {
                return addYears(this as Date, years);
            };
        };
    }

    function addAddHoursMethod(): void {
        if (!Date.prototype.addHours){
            Date.prototype.addHours = function (hours) {
                return addHours(this as Date, hours);
            };
        };
    }

    function addGetAgeMethod(): void {
        if (!Date.prototype.getAge){
            Date.prototype.getAge = function () {
                return getAge(this as Date);
            };
        };
    }

    function addYears(date: Date, years: number): Date {
        return new Date(date.setFullYear(new Date().getFullYear() + years));
    }

    function addHours(date: Date, hours: number): Date {
        return new Date(date.setTime(new Date().getTime() + (hours * 60 * 60 * 1000)));
    }

    function getAge(date: Date): number {
        const ageDiff: number = Date.now() - date.getTime();
        const ageDate: Date = new Date(ageDiff); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    export function format(date: Date, format: DateTimeFormat): string {
        switch (format) {
            case 'datetime':
                return `${date?.toLocaleDateString()} ${date.toLocaleTimeString([], { hour12: false })}`;
            case 'short-time':
                const hours: number = date.getHours();
                const minutes: number = date.getMinutes();                
                if (minutes && minutes > 0) {
                    return `${hours}h:${minutes.toString().padStart(2, '0')}m`;
                }
                else {
                    return `${hours}h`;
                }
            default:
                return date?.toLocaleDateString();
        }
    }

    export function licenseDateHasTime(date: Date): boolean {
        return (date.getHours() != 0 || date.getMinutes() != 0 || date.getSeconds() != 0) && `${date.getHours()}:${date.getMinutes()}` != '23:59';
    }
}