import { Injectable } from "../reflection/injectable";

@Injectable({ type: 'singleton' })
export class SessionStorageService {

    public constructor() {        
    }

    public set(key: string, value: any): void {
        window.sessionStorage.setItem(key, value != null ? JSON.stringify(value): null);
    }

    public get(key: string): any {
        const value: string = window.sessionStorage.getItem(key);
        return value ? JSON.parse(value): null;
    }

    public remove(key: string): void {
        window.sessionStorage.removeItem(key);
    }
}