export namespace QueryString {

    export function get(search?: string): any {
        const result: any = {};
        (search || window.location.search).replace('?', '')
            .split('&')
            .forEach(item => {
                const split: string[] = item.split('=');
                result[split[0]] = split[1];
            });
        return result;
    }

    export function addParamToUrl(url: string, name: string, value: string): string {
        const targetUrl: URL = new URL(url);
        targetUrl.searchParams.set(name, value);
        return targetUrl.toString();

    }
}