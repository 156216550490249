import { Num } from './num';

export namespace Dom {
    
    export function getComputedWidth(element: HTMLElement): number {
        let width: string = window.getComputedStyle(element).width;
        if (width) {
            if (width.endsWith('px')) {
                width = width.substring(0, width.length - 2);
            }
            return Num.toFloat(width);
        }
        else {
            return null;
        }
    }

    export function getComputedHeight(element: HTMLElement): number {
        let height: string = window.getComputedStyle(element).height;
        if (height) {
            if (height.endsWith('px')) {
                height = height.substring(0, height.length - 2);
            }
            return Num.toFloat(height);
        }
        else {
            return null;
        }
    }

    export function getComputedDisplay(element: HTMLElement): string {
        return window.getComputedStyle(element).display;
    }

    export function isScrolledIntoView(element: HTMLElement, fully: boolean = false): boolean {
        const { top, bottom}  = element.getBoundingClientRect();
        if (fully) {
            return (top >= 0) && (bottom <= window.innerHeight);
        }
        else {
            return top < window.innerHeight && bottom >= 0;
        }
    }

    export function isFullScreen(): boolean {
        const doc: any = document;
        return (doc.fullscreenElement && doc.fullscreenElement !== null) ||
        (doc.webkitFullscreenElement && doc.webkitFullscreenElement !== null) ||
        (doc.webkitDisplayingFullscreen) ||
        (doc.mozFullScreenElement && doc.mozFullScreenElement !== null) ||
        (doc.msFullscreenElement && doc.msFullscreenElement !== null);
    }

    export function offFullScreen(): void {
        if (isFullScreen()) {
            toggleFullScreen();
        }
    }

    export function onFullScreen(element?: HTMLElement): void {
        if (!isFullScreen()) {
            toggleFullScreen(element);
        }
    }

    export function toggleFullScreen(element?: HTMLElement): void {
        try {
            const isInFullScreen = isFullScreen();
            if (!isInFullScreen) {
                const targetElement: any = element || document.documentElement;
                if (targetElement.requestFullscreen) {
                    targetElement.requestFullscreen();
                } 
                else if (targetElement.mozRequestFullScreen) {
                    targetElement.mozRequestFullScreen();
                } 
                else if (targetElement.webkitEnterFullscreen) {
                    targetElement.webkitEnterFullscreen();
                } 
                else if (targetElement.webkitRequestFullScreen) {
                    targetElement.webkitRequestFullScreen();
                }
                else if (targetElement.msRequestFullscreen) {
                    targetElement.msRequestFullscreen();
                }
            } 
            else {
                const doc: any = document;
                if (doc.exitFullscreen) {
                    doc.exitFullscreen();
                } 
                else if (doc.webkitExitFullscreen) {
                    doc.webkitExitFullscreen();
                } 
                else if (doc.mozCancelFullScreen) {
                    doc.mozCancelFullScreen();
                } 
                else if (doc.msExitFullscreen) {
                    doc.msExitFullscreen();
                }
            }
        }
        catch (e: any) {
            logger.warn(e);
        }
    }

    export function lockOrientation(orientation: 'portrait' | 'landscape'): void {
        try {
            screen.orientation.lock(orientation);
        }
        catch (e: any) {
            logger.warn(e);
        }
    }

    export function unlockOrientation(): void {
        try {
            screen.orientation.unlock();
        }
        catch (e: any) {
            logger.warn(e);
        }
    }

    export function makeDraggable(element: HTMLElement): void {
        element.addEventListener('mousedown', (event: MouseEvent) => {
            const initialClickEventX: number = element.offsetLeft + element.offsetWidth / 2;
            const initialClickEventY: number = element.offsetTop + element.offsetHeight / 2;
            const factorX: number = initialClickEventX - event.pageX;
            const factorY: number = initialClickEventY - event.pageY;
          
            const moveAt = (pageX: number, pageY: number) => {
                const left: number = factorX + pageX - element.offsetWidth / 2;
                const top: number = factorY + pageY - element.offsetHeight / 2;
                if (left > 0 && left + element.offsetWidth < window.innerWidth){
                    element.style.left = `${left}px`;
                }
                if (top > 0 && top + element.offsetHeight < window.innerHeight){
                    element.style.top = `${top}px`;
                }
            }
            const onMouseMove = (event: MouseEvent) => {
                moveAt(event.pageX, event.pageY);
            }
            const onMouseUp = () => {
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
                element.onmouseup = null;
            }
            document.addEventListener('mousemove', onMouseMove);          
            document.addEventListener('mouseup', onMouseUp);
        });

    }

    export function removeDraggable(element: HTMLElement): void {
         element.style.left = null;
         element.style.top = null;
    }
}