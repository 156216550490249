import { Injectable } from "../reflection/injectable";

@Injectable({ type: 'singleton' })
export class CookieService {

    public constructor() {        
    }

    public set(name: string, value: string, options?: { path?: string, expires?: Date, httpOnly?: boolean, secure?: boolean, sameSite?: 'Lax' | 'Strict' }): void {        
        if (!value) {
            this.remove(name);
        } 
        else {
            let { path, expires, httpOnly, secure, sameSite } = options || {};
            const cookie: string[] = [`${name}=${value}`];            
            cookie.push(`path=${path || '/'}`);
            cookie.push(`expires=${(expires || new Date().addYears(1)).toUTCString()}`);
            if (httpOnly) {
                cookie.push(`httpOnly=${httpOnly}`);
            }
            if (secure) {
                cookie.push(`secure=true`);
            }
            if (sameSite) {
                cookie.push(`sameSite=${sameSite}`);
            } else {
                cookie.push(`sameSite=Lax`);
            }
            const cookieContent = cookie.join('; ');
            document.cookie = cookieContent;
        }
    }

    public get(name: string): string {
        const parts: string[] = `; ${document.cookie}`.split(`; ${name}=`);
        return parts && parts.length === 2 ? parts.pop().split(';').shift(): null;
    }

    public remove(name: string): void {
        const cookieContent = `${name}='';expires=${new Date(0)};path=/`;
        document.cookie = cookieContent;
    }

    public isConsent(id: string): boolean {        
        try {            
            const isConsent: boolean = OneTrust.GetDomainData()?.ConsentIntegrationData?.consentPayload?.purposes?.some((p: any) => p.Id == id && p.TransactionType == 'CONFIRMED');
            return isConsent == true;
        }        
        catch {
            return false;
        }
    }
}