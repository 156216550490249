import { Injectable } from "../reflection/injectable";
import { LocalStorageService } from "./local-storage.service";

type LogLevel = 'debug' | 'log' | 'info' | 'warn';

export class LogGroup {
    constructor (public message: any, public autoClose: boolean = false) {
    }
}

@Injectable({ type: 'singleton' })
export class ClientLogService {

    public constructor(private _localStorageService: LocalStorageService) {        
    }   

    public debug(message?: any, ...optionalParameters: any[]): void {
        this.checkLevel('debug') && console.log(...this.parseStart(message, ...optionalParameters));
        this.parseEnd(message, ...optionalParameters);
    }

    public log(message?: any, ...optionalParameters: any[]): void {
        this.checkLevel('log') && console.log(...this.parseStart(message, ...optionalParameters));
        this.parseEnd(message, ...optionalParameters);
    }

    public info(message?: any, ...optionalParameters: any[]): void {
        this.checkLevel('info') && console.info(...this.parseStart(message, ...optionalParameters));
        this.parseEnd(message, ...optionalParameters);
    }

    public warn(message?: any, ...optionalParameters: any[]): void {
        this.checkLevel('warn') && console.warn(...this.parseStart(message, ...optionalParameters));
        this.parseEnd(message, ...optionalParameters);
    }

    public error(message?: any, ...optionalParameters: any[]): void {
        this.checkLevel('error') && console.error(...this.parseStart(message, ...optionalParameters));
        this.parseEnd(message, ...optionalParameters);
    }

    public notice(message?: any, ...optionalParameters: any[]): void {
        this.checkLevel('notice') && console.info(...this.parseStart(message, ...optionalParameters));
        this.parseEnd(message, ...optionalParameters);
    }

    public closeGroup(): void {
        console.groupEnd();
    }

    private checkLevel(level: LogLevel | 'error' | 'notice'): boolean {        
        let value: boolean = false;
        try {
            if (['notice', 'error'].includes(level)) {
                value = true;
            }
            else {
                const level: LogLevel | 'enable' | 'disabled' = this._localStorageService.get('log-level');
                if (level && level != 'disabled') {
                    value = level.split(',').map(l => l.trim().toLowerCase()).includes(level);
                }
            }
        }
        catch (e: any) {
            console.error(e);
        }
        return value;
    }  

    private parseStart(value?: any, ...optionalParameters: any[]): any[] {
        if (value instanceof LogGroup) {
            console.group(value.message);
        }
        return [
            ...(value instanceof LogGroup ? []: [value]),
            ...optionalParameters
        ];
    }

    private parseEnd(value?: any, ...optionalParameters: any[]): void {
        if (value instanceof LogGroup && value.autoClose) {
            this.closeGroup();
        }
    }
}