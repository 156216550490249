export enum Key {
	A = 65,
	B = 66,
	C = 67,
	D = 68,
	E = 69,
	F = 70,
	G = 71,
	H = 72,
	I = 73,
	J = 74,
	K = 75,
	L = 76,
	M = 77,
	N = 78,
	O = 79,
	P = 80,
	Q = 81,
	R = 82,
	S = 83,
	T = 84,
	U = 85,
	V = 86,
	W = 87,
	X = 88,
	Y = 89,
	Z = 90,
	Number0 = 48,
	Number1 = 49,
	Number2 = 50,
	Number3 = 51,
	Number4 = 52,
	Number5 = 53,
	Number6 = 54,
	Number7 = 55,
	Number8 = 56,
	Number9 = 57,
	Numpad0 = 96,
	Numpad1 = 97,
	Numpad2 = 98,
	Numpad3 = 99,
	Numpad4 = 100,
	Numpad5 = 101,
	Numpad6 = 102,
	Numpad7 = 103,
	Numpad8 = 104,
	Numpad9 = 105,
	Multiply = 106,
	Add = 107,
	Subtract = 109,
	Decimal = 110,
	Divide = 111,
	F1 = 112,
	F2 = 113,
	F3 = 114,
	F4 = 115,
	F5 = 116,
	F6 = 117,
	F7 = 118,
	F8 = 119,
	F9 = 120,
	F11 = 122,
	F12 = 123,
	F13 = 124,
	F14 = 125,
	F15 = 126,
	Backspace = 8,
	Tab = 'Tab',
	Enter = 'Enter',
	SHIFT = 16,
	CTRL = 17,
	ALT = 18,
	META = 91,
	META_RIGHT = 93,
	CapsLock = 20,
	Esc = 'Esc',
	Spacebar = ' ',
	PageUp = 'PageUp',
	PageDown = 'PageDown',
	End = 'End',
	Home = 'Home',
	ArrowLeft = 'ArrowLeft',
	ArrowUp = 'ArrowUp',
	ArrowRight = 'ArrowRight',
	ArrowDown = 'ArrowDown',
	Insert = 45,
	Delete = 46,
	NumLock = 144,
	ScrLk = 145,
    PauseBreak = 19,
    Dash = 189 //'- _'
	//'; :': 186,
	//'= +': 187,
	//',': 188,
	//'.': 190,
	//'/ ?': 191,
	//'` ~': 192,
	//'[ {': 219,
	//'\\ |': 220,
	//'] }': 221,
	//'" \'': 222
}

export namespace Key {

	export function impliesTextUpdate(keyCode: number): boolean {
		switch (keyCode) {
			case Key.A:
			case Key.B:
			case Key.C:
			case Key.D:
			case Key.E:
			case Key.F:
			case Key.G:
			case Key.H:
			case Key.I:
			case Key.J:
			case Key.K:
			case Key.L:
			case Key.M:
			case Key.N:
			case Key.O:
			case Key.P:
			case Key.Q:
			case Key.R:
			case Key.S:
			case Key.T:
			case Key.U:
			case Key.V:
			case Key.W:
			case Key.X:
			case Key.Y:
			case Key.Z:
			case Key.Number0:
			case Key.Number1:
			case Key.Number2:
			case Key.Number3:

			case Key.Number4:
			case Key.Number5:
			case Key.Number6:
			case Key.Number7:
			case Key.Number8:
			case Key.Number9:
			case Key.Numpad0:
			case Key.Numpad1:
			case Key.Numpad2:
			case Key.Numpad3:
			case Key.Numpad4:
			case Key.Numpad5:
			case Key.Numpad6:
			case Key.Numpad7:
			case Key.Numpad8:
			case Key.Numpad9:
			case Key.Multiply:
			case Key.Add:
			case Key.Subtract:
			case Key.Decimal:
			case Key.Divide:
			case Key.Backspace:
			case Key.Spacebar:
			case Key.Insert:
			case Key.Delete:
				return true;
			default:
				return false;
		}
	}
}