declare global {
    interface Array<T> {
        last(): T;
        first(): T;
        getRange(start: number, length?: number): T[];
        sum(callbackfn: (item: T) => number): number;
    }
}

export namespace ArrayUtil {
    
    export function addExtensionMethods(): void {
        addLastMethod();
        addFirstMethod();
        addGetRangeMethod();
        addSumMethod(); 
        addDistinctByMethod();       
    }

    function addLastMethod(): void {
        if (!Array.prototype.last){
            Array.prototype.last = function(){
                return this[this.length - 1];
            };
        };
    }

    function addFirstMethod(): void {
        if (!Array.prototype.first){
            Array.prototype.first = function(){
                return this[0];
            };
        };
    }

    function addGetRangeMethod(): any {
        if (!Array.prototype.getRange){
            Array.prototype.getRange = function(start, length) {
                return ArrayUtil.getRange(this, start, length);
            };
        };
    }

    function addSumMethod(): void {
        if (!Array.prototype.sum){
            Array.prototype.sum = function(callbackfn) {
                return ArrayUtil.sum(this, callbackfn);
            };
        };
    }

    function addDistinctByMethod(): any {
        if (!Array.prototype.distinctBy){
            Array.prototype.distinctBy = function(callbackfn) {
                return ArrayUtil.distinctBy(this, callbackfn);
            };
        };
    }

    export function getRange<TType>(source: Array<TType>, start: number, length?: number): TType[] {
		if (source != null && (!length || source.length < (start + length)))
			length = source.length - start;
		if (source != null && source.length >= (start + length)) {
			return source.slice(start, start + length);
		}
		else
			return null;
    }

    export function sum<TItem>(items: TItem[], selector: (item: TItem) => number): number {
        return items.map(selector).reduce((a: number, b: number) =>{
            if (a || b) {
                return a + b;
            }
            else {
                return a ? a: b ? b: 0;
            }
        }, 0);
    }

    export function distinctBy<TItem>(items: TItem[], selector: (item: TItem) => any): TItem[] {
        const result: TItem[] = [];
        items.forEach(i => {
            if (!result.some(r => selector(r) == selector(i))) {
                result.push(i);
            }
        });
        return result;
    }   
}