import { Injectable } from "../reflection/injectable";

@Injectable({ type: 'singleton' })
export class LocalStorageService {

    public constructor() {        
    }

    public set(key: string, value: any): void {
        window.localStorage.setItem(key, value != null ? JSON.stringify(value): null);
    }

    public get(key: string): any {
        const value: string = window.localStorage.getItem(key);
        return value ? JSON.parse(value): null;
    }

    public remove(key: string): void {
        window.localStorage.removeItem(key);
    }
}