import { Injectable } from "../reflection/injectable";
import { ModalService } from "./modal.service";

export type MessageBoxResult = 'ok' | 'cancel';

export interface MessageBoxButton {
    title?: string;
    result: MessageBoxResult;
}

@Injectable({ type: 'singleton' })
export class MessageBoxService {

    public constructor(private _modalService: ModalService) { 
    }

    public async showQuestion(title: string, message: string, primary: MessageBoxResult, ...buttons: MessageBoxButton[]): Promise<MessageBoxResult> {
        const promise: Promise<MessageBoxResult> = new Promise<MessageBoxResult>((resolve: (result: MessageBoxResult) => void) => {
            const messageBoxElement: HTMLDivElement = document.createElement('div');
            messageBoxElement.classList.add('u-message-box');
            const modalContentElement: HTMLDivElement = document.createElement('div');
            modalContentElement.classList.add('u-modal-content');
            messageBoxElement.appendChild(modalContentElement);
            const titleElement: HTMLDivElement = document.createElement('div');
            titleElement.classList.add('u-message-box__title');
            titleElement.innerHTML = title;
            modalContentElement.appendChild(titleElement);
            const messageElement: HTMLDivElement = document.createElement('div');
            messageElement.classList.add('u-message-box__message');
            messageElement.innerHTML = message;
            modalContentElement.appendChild(messageElement);
            const buttonsElement: HTMLDivElement = document.createElement('div');
            buttonsElement.classList.add('u-message-box__buttons');
            modalContentElement.appendChild(buttonsElement);
            buttons.forEach(b => {
                const buttonElement: HTMLButtonElement = document.createElement('button');
                buttonElement.classList.add('u-button', b.result == primary ? 'u-button--primary': 'u-button--secondary');
                buttonElement.innerHTML = b.title || this.getResultTitle(b.result);
                buttonElement.addEventListener('click', () => {
                    this._modalService.close(messageBoxElement);
                    resolve(b.result);
                });
                buttonsElement.appendChild(buttonElement);
            });
            document.body.appendChild(messageBoxElement);
            this._modalService.open(messageBoxElement, 'modal');    
        });
        return promise;
    }

    public async showInfo(title: string, message?: string): Promise<MessageBoxResult> {
        const promise: Promise<MessageBoxResult> = new Promise<MessageBoxResult>((resolve: (result: MessageBoxResult) => void) => {
            const messageBoxElement: HTMLDivElement = document.createElement('div');
            messageBoxElement.classList.add('u-message-box');
            const modalContentElement: HTMLDivElement = document.createElement('div');
            modalContentElement.classList.add('u-modal-content');
            messageBoxElement.appendChild(modalContentElement);
            const titleElement: HTMLDivElement = document.createElement('div');
            titleElement.classList.add('u-message-box__title');
            titleElement.innerHTML = title;
            modalContentElement.appendChild(titleElement);
            if (message) {
                const messageElement: HTMLDivElement = document.createElement('div');
                messageElement.classList.add('u-message-box__message');
                messageElement.innerHTML = message;
                modalContentElement.appendChild(messageElement);
            }
            const buttonsElement: HTMLDivElement = document.createElement('div');
            buttonsElement.classList.add('u-message-box__buttons');
            modalContentElement.appendChild(buttonsElement);
            ['ok' as MessageBoxResult].forEach(b => {
                const buttonElement: HTMLButtonElement = document.createElement('button');
                buttonElement.classList.add('u-button', b == 'ok' ? 'u-button--primary': 'u-button--secondary');
                buttonElement.innerHTML = this.getResultTitle(b);
                buttonElement.addEventListener('click', () => {
                    this._modalService.close(messageBoxElement);
                    resolve(b);
                });
                buttonsElement.appendChild(buttonElement);
            });
            document.body.appendChild(messageBoxElement);
            this._modalService.open(messageBoxElement, 'modal');    
        });
        return promise;
    }

    private getResultTitle(result: MessageBoxResult): string {
        switch (result) {
            case 'ok':
                return USER_LOCALE.ok;
            case 'cancel':
                return USER_LOCALE.cancel;
            default:
                return null;
        }
    }
}