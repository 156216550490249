import { Injector } from "../reflection/injector";
import { ModalService } from "../services/modal.service";
import { ComponentBase } from "./component-base";

export class ModalComponent<TElement extends HTMLElement> extends ComponentBase<TElement> {

    private _modalService: ModalService = null;
    private _promise: Promise<any> = null;
    private _resolve: (data: any) => void = null;
    private _isLoadingData: boolean = false;
    private _isOpened: boolean = false;

    public constructor(node: TElement) {        
        super(node);

        this._modalService = Injector.get(ModalService);       
    }    

    public onInit(): void {
        const btnClose: HTMLButtonElement = this.node.querySelector('.u-modal-close-button');
        if (btnClose) {
            btnClose.addEventListener('click', () => this.close());
        }
    }

    public async open(data?: any): Promise<any> {
        this._promise = new Promise<any>((resolve: (data: any) => void) => {
            this._resolve = resolve;
        });
        this._modalService.open(data && data instanceof HTMLElement ? data : this.node, 'modal');
        this._isOpened = true;
        return this._promise;
    }

    public close(data?: any): void {
        if (this._isOpened) {
            if (data && data instanceof HTMLElement) {
                this._modalService.close(data);
                (data as any)._isOpened = false;
                this._resolve(data);
            } 
            else {
                this._modalService.close(this.node);
                this._isOpened = false;
                this._resolve(data);
                this._resolve = null;
            }
            this._promise = null;    
        }
    } 

    protected get isLoadingData(): boolean {
        return this._isLoadingData;
    }

    protected set isLoadingData(value: boolean) {
        if (this.isLoadingData != value) {
            this._isLoadingData = value;
            this.setLoader();
        }
    }

    private setLoader(): void {
        const parent: HTMLElement = this._node.querySelector('.loading-data');
        if (parent) {
            if (this._isLoadingData) {
                parent.insertAdjacentHTML('afterbegin',  `<div class="warm-up-container  warm-up-container--modal">
                    <div class="warm-up-loader"></div>
                </div>`);
            }
            else { 
                const warmUpContainer: HTMLDivElement = parent.querySelector('.warm-up-container');
                if (warmUpContainer) {
                    warmUpContainer.parentNode.removeChild(warmUpContainer);
                }
            }
        }       
    }
}