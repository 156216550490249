import { Reflector } from '../reflection/reflector';
import { Type } from '../reflection/types';
import { ComponentOptions } from './component';
import { ComponentBase } from './component-base';
import { InputOptions } from './input';

interface Data {
    type: Type<ComponentBase<any>>;
    metadata: ComponentMetadata;    
}

export interface EventListenerMetadata {
    selector?: string;
    eventName?: string;
    propertyKey: string;
    checkEvent?: (e: Event) => boolean;
}

export interface ChildRefMetadata {
    selector?: string;
    propertyKey: string;
    propertyType: any;
}

export interface ChildrenRefMetadata extends ChildRefMetadata {    
}

export interface InputMetadata {
    name?: string;
    propertyKey: string;
    propertyType: any;
    options: InputOptions;
}

export interface ComponentMetadata {
    selector?: string;
    children?: Type<ComponentBase<any>>[];
    childRefs?: ChildRefMetadata[];
    childrenRefs?: ChildrenRefMetadata[];
    inputs?: InputMetadata[];
    eventListeners?: EventListenerMetadata[];
}

export class ComponentData {
    
    private static _data: Map<Type<ComponentBase<any>>, Data> = new Map<Type<ComponentBase<any>>, Data>();

    public static registerComponent(type: Type<ComponentBase<any>>, options?: ComponentOptions): Data {
        let data = ComponentData._data.get(type);
        if (!data) {            
            data = { 
                type, 
                metadata: {
                    ...options,
                    childRefs: [],
                    childrenRefs: [],
                    inputs: [],
                    eventListeners: []
                }
            };
            ComponentData._data.set(type, data);
        }
        else {
            if (options) {
                if (data.metadata) {
                    data.metadata = {
                        ...data.metadata,
                        ...options
                    }
                }
                else {
                    data.metadata = {                    
                        ...options
                    };
                }
            }
        }
        return data;
    }

    public static registerChildRef(type: Type<ComponentBase<any>>, propertyKey: string, selector: string): void {
        const data: Data = this.registerComponent(type);
        data.metadata.childRefs.push({
            selector,
            propertyKey,
            propertyType: Reflector.getPropertyType(type.prototype, propertyKey)
        });
    }

    public static registerChildrenRef(type: Type<ComponentBase<any>>, propertyKey: string, childrenType: Type<any>, selector: string): void {
        const data: Data = this.registerComponent(type);
        data.metadata.childrenRefs.push({
            selector,
            propertyKey,
            propertyType: childrenType.name
        });
    }

    public static registerInput(type: Type<ComponentBase<any>>, propertyKey: string, name: string, options: InputOptions): void {
        const data: Data = this.registerComponent(type);
        data.metadata.inputs.push({
            name,
            propertyKey,
            propertyType: Reflector.getPropertyType(type.prototype, propertyKey),
            options: options
        });
    }

    public static registerEventListener(type: Type<ComponentBase<any>>, propertyKey: string, selector: string, eventName: string, checkEvent?: (e: Event) => boolean): void {
        const data: Data = this.registerComponent(type);
        data.metadata.eventListeners.push({
            selector,
            propertyKey,
            eventName,
            checkEvent
        });
    }

    public static getMetadata(type: Type<ComponentBase<any>>): ComponentMetadata {
        const data: Data = ComponentData._data.get(type);
        if (data) {
            return data.metadata;
        }
        else {
            return null;
        }
    }   
}