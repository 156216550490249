import { ErrorModalComponent } from "../../../app/web/components/organisms/error-modal/error-modal.component";
import { BootstrapService } from "../../../app/web/services/bootstrap.service";
import { RouterService, RouteState } from "../../../app/web/services/router.service";
import { TooltipService } from "../../../app/web/services/tooltip.service";
import { DictionaryObject } from "../core/types";
import { Injector } from "../reflection/injector";
import { MessageBoxButton, MessageBoxResult, MessageBoxService } from "../services/message-box.service";

export class ComponentBase<TElement extends HTMLElement> {

    private _isDisposed: boolean = false;
    private _routerService: RouterService = null;
    private _messageBoxService: MessageBoxService = null;
    private _tooltipService: TooltipService = null;
    private _eventListeners: { element: HTMLElement | Window, eventName: string, handler: (e: Event) => void }[] = [];
    private _initMode: 'load' | 'redirect' = null;    

    private _bootstrapService: BootstrapService = null;

    public constructor(protected _node: TElement) {
        this._messageBoxService = Injector.get(MessageBoxService);
        this._tooltipService = Injector.get(TooltipService);
    }

    public onInit(mode?: 'load' | 'redirect'): void { 
        this._initMode = mode;
    }

    public get node(): TElement {
        return this._node;
    }    

    protected getComponent<T>(selector: string, from?: HTMLElement): T {
        let component: T = null;
        const fromNode: HTMLElement = from || document.documentElement;
        if (fromNode) {
            const componentNode: HTMLElement = fromNode.querySelector(selector);
            if (componentNode && componentNode.componentRef && componentNode.componentRef.ref) {
                component = componentNode.componentRef.ref as any as T;
            }
        }
        return component;
    }    

    public getState(): any {
        return null;
    }

    public setState(state: any): void {

    }

    public get isTouch(): boolean {
        return document.body.classList.contains('touch');
    }

    protected get isLoadingPage(): boolean {
        if (!this._routerService) {
            this._routerService = Injector.get(RouterService);
        }
        return this._routerService.isLoadingPage;
    }

    protected redirect(url: string): void {
        if (CONFIG.CLIENT_NAVIGATION_MODE == 'render') {
            if (!this._routerService) {
                this._routerService = Injector.get(RouterService);
            }
            this._routerService.redirect(url);            
        }
        else {
            window.location.assign(url);
        }
    }

    protected reload(): void {
        if (CONFIG.CLIENT_NAVIGATION_MODE == 'render') {
            if (!this._routerService) {
                this._routerService = Injector.get(RouterService);
            }
            this._routerService.reload();            
        }
        else {
            window.location.reload();
        }
    }

    protected pushState(url: string, state?: RouteState): void {
        history.pushState(state || this.getState(), null, url);
    }

    protected replaceState(url: string, state?: RouteState): void {
        history.replaceState(state || this.getState(), null, url);
    }

    protected dispatchCustomWindowEvent(eventName: string, data?: any): void {
        window.dispatchEvent(new CustomEvent(eventName, { detail: data }));
    }

    protected addCustomWindowEventListener(eventName: string, callback: (data: any) => void): void {
        const handler: (e: Event) => void = (e: Event) => callback((e as CustomEvent).detail);
        this._eventListeners.push({ element: window, eventName, handler });
        window.addEventListener(eventName, handler);
    }

    protected dispatchCustomEvent(eventName: string, data?: any): void {
        this._node.dispatchEvent(new CustomEvent(eventName, { detail: data }));
    }

    public addCustomEventListener(eventName: string, callback: (data: any) => void, selector?: string): void {        
        const element: HTMLElement = selector ? this.node.querySelector(selector): this._node;
        if (element) {
            const handler: (e: Event) => void = (e: Event) => callback((e as CustomEvent).detail);
            this._eventListeners.push({ element, eventName, handler });
            element.addEventListener(eventName, handler);
        }        
    }

    public addEventListener(eventName: string, callback: (e?: any) => void, target?: string | HTMLElement): void {
        const element: HTMLElement = target ? typeof target == 'string' ? this.node.querySelector(target): target: this._node;
        if (element) {
            const handler: (e: Event) => void = (e: Event) => callback(e);
            this._eventListeners.push({ element, eventName, handler });
            element.addEventListener(eventName, handler);
        }
    }

    public addWindowEventListener(eventName: string, callback: (e?: any) => void): void {
        const handler: (e: Event) => void = (e: Event) => callback(e);
        this._eventListeners.push({ element: window, eventName, handler });
        window.addEventListener(eventName, handler);
    }

    protected getValidationErrors(validationErrors: DictionaryObject<string[]>): string[] {
        if (validationErrors) {
            return Object.keys(validationErrors).map(k => {
                const errors: string[] = validationErrors[k];
                return errors && errors.length ? errors: []
            }).flatMap(e => e);
        }
    }

    protected setErrors(selector: string, ...errors: string[]): void {
        const errorsElement: HTMLUListElement = this.node.querySelector(selector);
        if (errorsElement) {
            if (errors && errors.length) {
                errorsElement.innerHTML = errors.map(e => {                
                    return `<li>${typeof e === 'string' ? e: e}</li>`;
                }).join('<br/>');
                errorsElement.classList.add('u-error-list--show');
            }
            else {
                errorsElement.innerHTML = '';
                errorsElement.classList.remove('u-error-list--show');
            }
        }
        this._tooltipService.fragment(errorsElement);
    }

    protected clearErrors(selector: string): void {
        const errorsElement: HTMLUListElement = this.node.querySelector(selector);
        if (errorsElement) {
            errorsElement.innerHTML = '';
            errorsElement.classList.remove('u-error-list--show');
        }
    }

    public focus(selector?: string, time: number = 0): void {
        const element: HTMLElement = selector ? document.querySelector(selector): this._node;
        if (element) {
            setTimeout(() => {
                element.focus();
            }, time);
        }
    }

    public getInnerHTML(selector?: string): string {
        const element: HTMLElement = selector ? document.querySelector(selector): this._node;
        return element ? element.innerHTML: null;
    }

    public setInnerHTML(value: string, selector?: string): void {
        const elements: HTMLElement[] = selector ? Array.from(document.querySelectorAll(selector)): [this._node];
        if (elements?.length) {
            elements.forEach(e => e.innerHTML = value);
        }
    }

    public addOrRemoveClass(add: boolean, className: string, selector?: string): void {
        if (add) {
            this.addClass(className, selector);
        }
        else {
            this.removeClass(className, selector);
        }
    }    

    public toggleClasses(value: boolean, trueClassName: string, falseClassName: string, element?: HTMLElement): void {
        const targetElement: HTMLElement = element || this.node;
        if (value) {
            targetElement.classList.remove(falseClassName);
            targetElement.classList.add(trueClassName);
        }
        else {
            targetElement.classList.remove(trueClassName);
            targetElement.classList.add(falseClassName);
        }
    }

    public addClass(className: string, selector?: string): void {
        const elements: HTMLElement[] = selector ?  Array.from(document.querySelectorAll(selector)): [this._node];
        if (elements && elements.length) {
            elements.forEach(element => element.classList.add(className));
        }
    }

    public removeClass(className: string, selector?: string): void {
        const elements: HTMLElement[] = selector ?  Array.from(document.querySelectorAll(selector)): [this._node];
        if (elements && elements.length) {
            elements.forEach(element => element.classList.remove(className));
        }
    }

    protected setFormData(data: any): void {
        Object.keys(data).forEach(k => {
            let inputElement: HTMLInputElement = this._node.querySelector(`.u-input[name=${k}]`);
            if (!inputElement) {
                const childElement: HTMLInputElement = this._node.querySelector(`.u-input input[name=${k}]`);
                if (childElement) {
                    inputElement = childElement.parentElement as HTMLInputElement;
                }
            }
            if (inputElement) {
                this.setFormInputValue(inputElement, data[k]);
            }
        });
    }

    protected getFormData(selector: string, ...excluded: string[]): any {
        const data: any = {};
        const formElement: HTMLElement = document.querySelector(selector);
        if (formElement) {
            const inputs: HTMLInputElement[] = Array.from(formElement.querySelectorAll('.u-input'));
            inputs.forEach(i => { 
                if (i.name && !(excluded || []).includes(i.name)) {
                    data[i.name] = this.getFormInputValue(i);
                }
            });
        }
        return data;
    }

    protected getFormInputValue(input: HTMLInputElement): any {
        if (input.classList.contains('a-radio-button')) {
            return (document.querySelector(`input[name='${input.name}']:checked`) as HTMLInputElement || { value: null }).value;
        }
        else {
            if (input.componentRef && input.componentRef.ref && (input.componentRef.ref as any).value !== undefined) {
                return (input.componentRef.ref as any).value;
            }
            else {
                return input.value;
            }
        }
    }

    protected setFormInputValue(input: HTMLInputElement, value: any): any {
        if (input.classList.contains('a-radio-button')) {
            if (value) {
                (document.querySelector(`input[name='${input.name}'][value='${value}']`) as HTMLInputElement).checked = true;
            }
        }
        else {
            if (input.componentRef && input.componentRef.ref && (input.componentRef.ref as any).value !== undefined) {
                (input.componentRef.ref as any).value = value;
            }
            else {
                input.value = value;
            }
        }
    }
    
    protected registerFormFieldBlur(selector: string, callback: (field: any) => void): void {
        const formElement: HTMLElement = document.querySelector(selector);
        if (formElement) {
            const inputs: HTMLInputElement[] = Array.from(formElement.querySelectorAll('.u-input'));
            inputs.forEach(i => {
                i.addEventListener('blur', () => {
                    const field: any = {};
                    field[i.name] = this.getFormInputValue(i);
                    callback(field);
                });
            });
        }
    }

    protected registerFormFieldInput(selector: string, callback: (field: any) => void): void {
        const formElement: HTMLElement = document.querySelector(selector);
        if (formElement) {
            const inputs: HTMLInputElement[] = Array.from(formElement.querySelectorAll('.u-input'));
            inputs.forEach(i => {
                i.addEventListener('input', () => {
                    const field: any = {};
                    field[i.name] = this.getFormInputValue(i);
                    callback(field);
                });
            });
        }
    }

    protected showUnexpectedError(error: any): void {
        const errorModalElement: HTMLDivElement = document.querySelector('.o-error-modal');
        if (errorModalElement) {
            (errorModalElement.componentRef.ref as ErrorModalComponent).open(error);
        }
        else {
            logger.error(error);
        }
    }

    protected disable(selector?: string): void {
        const element: HTMLElement = selector ? document.querySelector(selector): this._node;
        if (element) {
            (element as any).disabled = true;
        }
    }

    protected enable(selector?: string): void {
        const element: HTMLElement = selector ? document.querySelector(selector): this._node;
        if (element) {
            (element as any).disabled = false;
        }
    }

    protected dataset(name: string, value: string, selector?: string): void {
        const element: HTMLElement = selector ? document.querySelector(selector): this._node;
        if (element) {
            element.dataset[name] = value;
        }
    }

    public async showQuestion(title: string, message: string, primary: MessageBoxResult, ...buttons: MessageBoxButton[]): Promise<MessageBoxResult> {
        return await this._messageBoxService.showQuestion(title, message, primary, ...buttons);
    }

    public async showInfo(title: string, message?: string): Promise<MessageBoxResult> {
        return await this._messageBoxService.showInfo(title, message);
    }

    public dispose(): void { 
        this._eventListeners.forEach(listener => {
            const { element, eventName, handler } = listener;
            element.removeEventListener(eventName, handler);
        });
        this._isDisposed = true; 
        this._node.componentRef = null;             
    }

    public fragment(): void {
        if (!this._bootstrapService) {
            this._bootstrapService = Injector.get(BootstrapService);
        }
        this._bootstrapService.fragment();
    }

    protected hideBodyOverflow(): void {
        const bodyOverflowStyle: HTMLStyleElement = document.createElement('style');
        bodyOverflowStyle.id = 'body-overflow-style';
        bodyOverflowStyle.innerText = `html { overflow-y: hidden; }`;
        document.body.appendChild(bodyOverflowStyle);
    }

    protected showBodyOverflow(): void {
        const bodyOverflowStyle: HTMLStyleElement = document.getElementById("body-overflow-style") as HTMLStyleElement;
        if (bodyOverflowStyle && bodyOverflowStyle.parentNode) {
            bodyOverflowStyle.parentNode.removeChild(bodyOverflowStyle);
        }
    }

    protected hideTooltips(): void {
        this._tooltipService.hideTooltips();
    }

    protected get initMode(): 'load' | 'redirect' {
        return this._initMode;
    }

    public get isDisposable(): boolean {
        return this._node.dataset.disposable !== 'false';
    }

    public set isDisposable(value: boolean) {
        if (value) {
            delete this._node.dataset.disposable;
        }
        else {
            this._node.dataset.disposable = 'false';
        }
    }

    public get isDisposed(): boolean {
        return this._isDisposed;
    }
}